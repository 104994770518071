export const ADD_STAFF = "ADD_STAFF";
export const GET_STAFF = "GET_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const ADD_NOTICE = "ADD_NOTICE";
export const GET_NOTICE = "GET_NOTICE";
export const UPDATE_NOTICE = "UPDATE_NOTICE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const GET_MESSAGE = "GET_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const ADD_EVENT = "ADD_EVENT";
export const GET_EVENT = "GET_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const ADD_PHOTO = "ADD_PHOTO";
export const GET_PHOTO = "GET_PHOTO";
export const UPDATE_PHOTO = "UPDATE_PHOTO";

